/*
 * Nick Lewyn
 * 2020-01-27
 * © Copyright 2020 NursingABC, Inc.  All Rights Reserved.
 */
import {
  REQUIRED_ERROR,
} from './Globals';

export function decodeJwt(jwt) {
  const base64Payload = jwt.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');

  return JSON.parse(window.atob(base64Payload));
}

export function fileHumanReadableSize(numBytes) {
  if (numBytes > 2 ** 30) {
    return `${(numBytes / 2 ** 30).toFixed(2)} GB`;
  } else if (numBytes > 2 ** 20) {
    return `${(numBytes / 2 ** 20).toFixed(2)} MB`;
  } else if (numBytes > 2 ** 10) {
    return `${(numBytes / 2 ** 10).toFixed(2)} KB`;
  }
  return `${numBytes} B`;
}

export function getFormattedPhone(phone) {
  if (!phone) {
    return '';
  }
  const digitsOnly = phone.replace(/\D/g, '');
  const match = phone.match(/^(\d+)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const countryCode = (match[1] ? `+${match[1]} ` : '');
    return `${countryCode}(${match[2]}) ${match[3]}-${match[4]}`;
  }

  return `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6)}`;
}

export function formatNumberAsUScurrency(value) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(value);
}

export function getErrorKey(name) {
  return `${name}Error`;
}

export function validateFormInputs(inputValues, inputValidationRules) {
  const errorDict = {};
  for (let i = 0; i < Object.keys(inputValidationRules).length; i++) {
    const inputName = Object.keys(inputValidationRules)[i];
    const validation = inputValidationRules[inputName];
    if (validation.isRequired) {
      if (!inputValues[inputName]) {
        errorDict[getErrorKey(inputName)] = REQUIRED_ERROR;
        continue;
      }
    }
    if (validation.isInvalid && validation.isInvalid()) {
      errorDict[getErrorKey(inputName)] = validation.isInvalid();
    }
  }

  return errorDict;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
