/*
 * Ryan O'Dowd
 * 2019-01-09
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */

// https://stackoverflow.com/questions/6511542/force-javascript-exception-error-when-reading-an-undefined-object-property
export const nabcGlobalObject = (obj) => {
  if (document.documentMode) {
    // this is Internet Explorer, and it doesn't support Proxy, and Proxy can't be pollyfilled by Babel
    return obj;
  }

  const handler = {
    get(target, key) {
      if (key in target || key === '$$typeof') {
        return target[key];
      }

      throw new Error(`Property '${key}' is not defined on this object`);
    },
    set(target, key, value, receiver) {
      throw new Error('This object is immutable');
    },
  };

  return new Proxy(obj, handler);
};

export const TRANSCRIPT_REQUEST_STATUSES = nabcGlobalObject({
  Requested: 1,
  Completed: 3,
  Invalid: 4,
});

// departments
export const BIOLOGY = 'biology';
export const CHEMISTRY = 'chemistry';
export const ENGLISH = 'english';
export const ETHICS = 'ethics';
export const MATHEMATICS = 'mathematics';
export const PSYCHOLOGY = 'psychology';
export const SOCIOLOGY = 'sociology';

// envs
export const LOCAL_ENV = 'local';
export const DEV_ENV = 'dev';
export const PROD_ENV = 'prod';
export const BETA_ENV = 'beta';

// perms
export const ACCEPTANCE_LETTER = 'acceptance_letter';
export const APPLY_DISCOUNT = 'apply_discount';
export const DEVELOPER = 'developer';
export const LOW_GRADES = 'low_grades';
export const EXPORT_OUTCOMES = 'export_outcomes';
export const FINANCE = 'finance';
export const PAID_IN_FULL = 'paid_in_full';
export const PAYROLL = 'payroll';
export const REFUNDS = 'refunds';
export const INSTRUCTOR_ASSIGNMENT = 'instructor_assignment';
export const INTEGRITY_VIOLATIONS = 'integrity_violations';
export const CANVAS_LOCKING = 'canvas_locking';
export const ACADEMIC_HONESTY = 'academic_honesty';
export const READ_STUDENT_INFO = 'read_student_info';
export const EDIT_STUDENT_INFO = 'edit_student_info';
export const READ_SCHOOL_INFO = 'read_school_info';
export const EDIT_SCHOOL_INFO = 'edit_school_info';
export const ELECTRONIC_TRANSCRIPTS = 'electronic_transcripts';
export const AUTHORIZATION_CODES = 'authorization_codes';
export const GRADES = 'grades';
export const REGISTRATIONS = 'registrations';
export const REGISTRATION_REPORTS = 'registration_reports';
export const TRANSCRIPTS = 'transcripts';
export const SYLLABI = 'syllabi';
export const SECURE_FILES = 'secure_files';
export const MANUALLY_ASSIGN_INSTRUCTOR = 'manually_assign_instructor';
export const CONTRACT_EDITOR_SHARING = 'contract_editor_sharing';
export const VIDEO_CAPTIONING = 'video_captioning';
export const PIE = 'school_relations';
export const UNWITHDRAW_ENROLLMENT = 'unwithdraw_enrollment';
export const STUDENT_PROGRESS = 'student_progress';
export const DISABLE_STUDENT = 'disable_student';
export const PARTNER_SCHOOLS = 'partner_schools';
export const CREATE_INSTITUTION_OR_CAMPUS = 'create_institution_or_campus';
export const CREATE_AND_EDIT_TERMS = 'create_and_edit_terms';
export const INSTRUCTIONAL_DESIGN = 'instructional_design';
export const SCHOLARSHIPS = 'academic_scholarships';
export const RESTORE_ENROLLMENT = 'restore_enrollment';
export const SIDE_VIEW_CAMERA = 'side_view_camera';

let env = PROD_ENV;
let canvasUrl = 'https://portagelearning.instructure.com';
if (window.document.location.hostname === 'localhost') {
  env = LOCAL_ENV;
  canvasUrl = 'https://portagelearning.test.instructure.com';
}
if (window.document.location.hostname.startsWith('dev.')) {
  env = DEV_ENV;
  canvasUrl = 'https://portagelearning.test.instructure.com';
}
if (window.document.location.hostname.startsWith('beta.')) {
  env = BETA_ENV;
  canvasUrl = 'https://portagelearning.test.instructure.com';
}

const themePrimary = {
  50: '#f1e4e6',
  100: '#ddbdc2',
  200: '#c69199',
  300: '#af6470',
  400: '#9d4351',
  500: '#8c2232',
  600: '#841e2d',
  700: '#791926',
  800: '#6f141f',
  900: '#5c0c13',
  A100: '#ff9098',
  A200: '#ff5d69',
  A400: '#ff2a39',
  A700: '#ff1122',
  contrastDefaultColor: 'light',
};
const themeAccent = {
  A100: '#bdccde',
  A200: '#91abc8',
  A400: '#225691',
  A700: '#19457e',
  contrastDefaultColor: 'light',
};

const Globals = {
  env,
  canvasUrl,
  colors: {
    primary: themePrimary['500'],
    accent: themeAccent.A400,
    error: '#d07070',

    light: '#fefefe',
    lightGray: '#ccc',
    mediumGray: '#5e6472',
    darkGray: '#3d3d3d',
    dark: '#212121',
    green: '#00b300',
    white: '#ffffff',

    alphaFeature: '#f5cc00',

    courseStatuses: {
      withdrawn: '#e53935',
      completed: '#8BC34A',
      ng: '#212121',
      active: '#1976D2',
      unassigned: '#FFFFFF',
      openForReview: '#6A0313',
      openForGradeChange: '#6A0313',
    },

    ettsSchoolAccounts: {
      notInvited: '#e53935',
      pendingAcceptance: '#1976D2',
      accountConfirmed: '#8BC34A',
      pendingTranscripts: '#f5cc00',
    },

    instructorAssignment: {
      min: '#bbb',
      max: '#777',
      hardCap: '#f44',
      tmpCap: '#ffff44',
      simulationWarning: '#aaf',
    },

    transcripts: {
      completed: '#8BC34A',
      uncompleted: '#1976D2',
      invalid: '#e53935',
      electronicNotAvailable: '#ff990030',
    },
  },
  themePrimary,
  themeAccent,
  dateFormatForGradesComparison: 'YYYY-MM-DD',
  s3Buckets: {
    etts: 'etts',
    privateAssets: 'private_assets',
  },
  userDepartments: {
    facultyDepartmentChair: 'Faculty Dept Chair',
  },
};

export const routes = {
  academicHonesty: '/academic_honesty',
  academicScholarships: '/academic_scholarships',
  account: '/account',
  updateStudentProgressData: '/export_student_progress/update_student_progress_data',
  admins: '/admins',
  awsTools: '/developer_tools/aws',
  createSections: '/create_sections',
  cleanInput: '/clean_input',
  companyDashboard: '/company_dashboard',
  contractEditorSharing: '/contract_editing',
  databaseTools: '/developer_tools/database',
  discountCodes: '/authorization_codes',
  etts: '/electronic_transcript_transfer',
  exportOutcomes: '/export_outcomes',
  studentProgress: '/student_progress',
  home: '/',
  terms: '/terms',
  instructorAssignment: '/instructor_assignment',
  integrityViolations: '/integrity_violations',
  lowGradeWarnings: '/low_grade_warnings',
  mergeStudents: '/students/merge',
  newContacts: '/new_contacts',
  partnerAgreements: '/partner_agreements',
  partnerSchools: '/partner_schools',
  partnerStudentImport: '/partner_student_import',
  payroll: '/payroll',
  refunds: '/refunds',
  registrations: '/registrations',
  reporting: '/reporting',
  schoolRelationsDashboard: '/school_relations_dashboard',
  secureFiles: '/secure_files',
  settings: '/settings',
  statusUpdates: '/status_updates',
  syllabi: '/syllabi',
  transcripts: '/transcripts',
  studentManagement: '/students/:studentId?',
  videoCaptioning: '/video_captioning',
  wiki: 'https://wiki.nursingabc.com',
};

export const REQUIRED_ERROR = 'Required';

export default Globals;
