/*
 * Ryan O'Dowd
 * 2020-10-06
 * © Copyright 2020 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../../Globals';

const DIALOG_CONTENT_WIDTH = 600;

const styles = {
  container: {
    width: '100%',
    textAlign: 'left',
    color: Globals.colors.dark,
  },
  detailsOuterWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  detailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 12,
    paddingBottom: 16,
    width: 300,
    borderBottomColor: `${Globals.colors.accent}22`,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  under18Icon: {
    color: Globals.colors.accent,
  },
  actionsWrapper: {
    flexGrow: 1,
  },
  actionOptions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  expand: {
    flexGrow: 1,
  },
  innerDialogContent: {
    width: DIALOG_CONTENT_WIDTH - 50,
  },
  formFieldWrapper: {
    padding: 16,
    paddingTop: 0,
  },
  formFieldLabel: {
    color: Globals.colors.accent,
    marginTop: 0,
    marginLeft: 0,
    marginBottom: 4,
    marginRight: 0,
    fontSize: '0.95rem',
    fontWeight: '200',
  },
  formFieldContent: {
    margin: 0,
    fontSize: '1.1rem',
    display: 'flex',
    color: `${Globals.colors.dark}aa`,
    fontWeight: '100',
    overflowWrap: 'anywhere',
  },
  detailsButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  studentCoursesWrapper: {
    padding: 16,
    marginRight: 32,
  },
  addCourseAndTranscriptHistoryButtonsWrapper: {
    display: 'flex',
    marginTop: 16,
  },
  previousRecordsIconWrapper: {
    padding: 0,
    marginLeft: 8,
  },
  textField: {
    marginRight: 8,
  },
  tooltipTrigger: {
    textDecorationLine: 'underline',
    textDecorationStyle: 'dotted',
    textDecorationSkipInk: 'none',
  },
  expansionPanelSummaryInner: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  ngOverlay: {
    opacity: 0.5,

    /* @TODO: for some reason, this throws a duplicate key error on the span that has a span with key = c.id that uses this style; seems like a bug in radium, but it's easy to just comment this out for now instead
    ':hover': {
      opacity: 1,
    },
    */
  },
  statusWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 4,
    height: '100%',
    color: Globals.colors.lightGray,
  },
  courseHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  courseName: {
    color: Globals.colors.accent,
  },
  courseInstructor: {
    color: Globals.colors.primary,
    fontSize: 12,
  },
  panelDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  startDateWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  forceStartIcon: {
    color: Globals.colors.alphaFeature,
    fontSize: 20,
  },
  dataItemWrapper: {
    display: 'flex',
    marginBottom: 32,
  },
  dataItemInnerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    overflowWrap: 'break-word',
  },
  dataItemLabel: {
    color: Globals.colors.accent,
    fontSize: '0.8rem',
  },
  dataItemValue: {
    fontSize: '0.8rem',
  },
  listDataItemValue: {
    fontSize: '0.8rem',
    padding: 0,
  },
  datePicker: {
    display: 'none',
  },
  settingsRow: {
    display: 'flex',
  },
  muiFormControlLabelCustom: {
    marginRight: '8px',
  },
  transcriptHistoryRequestButton: {
    marginLeft: 10,
  },
  transcriptHistoryDialogCloseButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formControl: {
    minWidth: 200,
  },
  formControlRoot: {
    maxWidth: '50%',
  },
  formRow: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: 10,
    marginBottom: 10,
  },
  spacer: {
    width: 10,
  },
  highlighted: {
    borderStyle: 'solid',
    borderColor: '#225691',
  },
  alertContainer: {
    display: 'flex',
  },
  clickableStyle: {
    cursor: 'pointer',
  },
  courseChangeOfRegistration: {
    fontSize: '.75rem',
    marginLeft: 5,
  },
  enrollmentTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  cameraIcon: {
    color: Globals.colors.error,
    marginRight: '5px',
  },
  sideViewRequired: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.8rem',
    fontWeight: '200',
    marginTop: '-10px',
    paddingLeft: '16px',
  },
};

export default styles;
