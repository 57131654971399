/*
 * Stevie Michalik
 * 2023-07-26
 * © Copyright 2023 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  getPartTimeInstructorPayroll,
  getPartTimeInstructorPayrollExport,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  FINANCE,
} from '../../Globals';
import styles from './styles';

const _getLastDayOfPayPeriodDateObject = () => {
  const d = new Date();
  d.setDate(15);
  return d;
};

const Payroll = () => {
  const dispatch = useDispatch();
  const [_selectedPeriod, setSelectedPeriod] = useState(_getLastDayOfPayPeriodDateObject().toISOString().slice(0, 10));
  const admin = useSelector((state) => state.admin);
  const partTimeInstructorPayroll = useSelector((state) => state.partTimeInstructorPayroll);

  useEffect(() => {
    dispatch(getPartTimeInstructorPayroll(_selectedPeriod));
  }, [dispatch, _selectedPeriod]);

  const _exportPartTimeInstructorPayrollExport = (date) => {
    dispatch(getPartTimeInstructorPayrollExport(date));
  };

  const _returnDateOptions = () => {
    const dates = [];
    const currentPeriod = _getLastDayOfPayPeriodDateObject();
    for (let numPeriodsDisplayed = 1; numPeriodsDisplayed <= 6; numPeriodsDisplayed++) {
      dates.push(<MenuItem key={currentPeriod} value={currentPeriod.toISOString().slice(0, 10)}>{currentPeriod.toISOString().slice(0, 10)}</MenuItem>);
      currentPeriod.setMonth(currentPeriod.getMonth() - 1);
    }
    return dates;
  };

  return (
    <div style={styles.container}>
      <div style={styles.headerContainer}>
        <div style={styles.headerText}>
          <h2>Part Time Instructor Payroll</h2>
          <p>See number of enrollments for each instructor by pay period and export to .csv file</p>
        </div>
      </div>
      <div style={styles.selectorTotalContainer}>
        <div style={styles.headerText}>
          <p><strong>Payroll Period End Date:</strong></p>
        </div>
        <div style={styles.selectorContainer}>
          <Select
            style={styles.selectOptions}
            defaultValue={_selectedPeriod}
            id='exception'
            label='Type'
            onChange={(e) => {
              setSelectedPeriod(e.target.value);
            }}
          >
            {_returnDateOptions()}
          </Select>
        </div>
      </div>
      <div>
        {!!(admin.perms[FINANCE]) &&
            <Paper elevation={3}>
              <div style={styles.sectionContainer}>
                <div style={styles.instructorPayHeader}>
                  <div style={styles.headerText}>
                    <h3>Instructor Pay View</h3>
                    <p>Displays number of enrollments by course credits for each Instructor</p>
                  </div>
                  <div style={styles.buttonContainer}>
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={() => {
                        _exportPartTimeInstructorPayrollExport(_selectedPeriod);
                      }}
                    >Export</Button>
                  </div>
                </div>
                <div style={styles.tableWrapper}>
                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Instructor Name</TableCell>
                        <TableCell>Three Credit Enrollments</TableCell>
                        <TableCell>Four Credit Enrollments</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {partTimeInstructorPayroll.sort((a, b) => {
                        let valueOfA = a.last_name.concat(a.first_name);
                        let valueOfB = b.last_name.concat(b.first_name);
                        if (typeof valueOfA === 'string') {
                          valueOfA = valueOfA.toLowerCase().trim();
                          valueOfB = valueOfB.toLowerCase().trim();
                        }
                        return valueOfA > valueOfB ? 1 : -1;
                      }).map((instructor) => {
                        return (
                          <TableRow key={instructor.id}>
                            <TableCell>{instructor.last_name}, {instructor.first_name}</TableCell>
                            <TableCell>{instructor.three_credit_enrollments}</TableCell>
                            <TableCell>{instructor.four_credit_enrollments}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </Paper>
        }
      </div>
    </div>
  );
};

export default Payroll;
