/*
 * Ryan O'Dowd
 * 2020-10-06
 * © Copyright 2020 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Switch,
  TextField,
  Tooltip,
} from '@material-ui/core';
import {
  DateTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Globals, {
  APPLY_DISCOUNT,
  DEVELOPER,
  DISABLE_STUDENT,
  EDIT_STUDENT_INFO,
  FINANCE,
  GRADES,
  MANUALLY_ASSIGN_INSTRUCTOR,
  PAID_IN_FULL,
  READ_STUDENT_INFO,
  RESTORE_ENROLLMENT,
  SIDE_VIEW_CAMERA,
  UNWITHDRAW_ENROLLMENT,
} from '../../../Globals';
import {
  applyDiscountCodeToEnrollment,
  backfillGrades,
  clearCanvasStudentEnrollmentUpdateFlag,
  clearEnrollmentRefundEligibility,
  clearNABCCanvasCoursePoints,
  closeForceConcludeDialog,
  extendStudentQuizTime,
  fetchAssignableCourseInstructors,
  fetchCognitoStudentData,
  fetchCourseSyllabus,
  fetchCourses,
  fetchGrades,
  fetchNABCCanvasCoursePoints,
  fetchPreviousInfoForStudent,
  fetchRefundEligibility,
  fetchStudentCourses,
  fetchUnassignedStudentIds,
  forceConcludeStudentCourse,
  manuallyAssignInstructor,
  manuallyEnrollStudent,
  requestRefund,
  updateCanvasStudentCourseEnrollment,
  updateEnrollments,
  updateStudent,
  updateTransactionEnrollment,
} from '../../../actions';
import {
  formatNumberAsUScurrency,
  getFormattedPhone,
} from '../../../utilities';
import BookIcon from 'mdi-react/BookIcon';
import CakeIcon from 'mdi-react/CakeIcon';
import CameraEnhanceOutlineIcon from 'mdi-react/CameraEnhanceOutlineIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from '@material-ui/icons/Close';
import CreateDiscountDialog from '../../DiscountCodes/CreateDiscountDialog';
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon';
import EditIcon from '@material-ui/icons/Edit';
import EditStudentDialog from '../EditStudentDialog';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FlagIcon from '@material-ui/icons/Flag';
import Grades from '../Grades';
import HasViolation from '../../HasViolation';
import ManualEnrollment from '../ManualEnrollment';
import MomentUtils from '@date-io/moment';
import MuiAlert from '@material-ui/lab/Alert';
import PencilIcon from 'mdi-react/PencilIcon';
import PropTypes from 'prop-types';
import React from 'react';
import RestoreClockIcon from 'mdi-react/RestoreClockIcon';
import SettingsIcon from '@material-ui/icons/Settings';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import TranscriptHistory from '../TranscriptHistory';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import moment from 'moment';
import styles from './styles';

const SELECT_INITIAL_VALUE = 'none';
const REQUIRED = 'Required';

const PRETTY_DATE_FORMAT = 'MMM D, YYYY';
const CHANGE_IN_REGISTRATION = 'Change in registration';
const OTHER = 'Other';

const REFUND_REASONS = [
  "There was a change in my program's requirements, and I no longer need this course",
  'A previous course I took was accepted, and I no longer need this course',
  "I enrolled in this course prior to checking with an advisor, and realize that I don't need it",
  'Personal/Financial hardships are keeping me from completing this course',
  "I'm not going to meet a program grade requirement if I continue this course",
  "I'm not going to improve my overall GPA to meet an admissions requirement if I continue this course",
  'My school of intent no longer accepts courses through Portage Learning',
  "I'm changing plans and am no longer pursuing my initial program of intent",
  'This course is not meeting my expectations',
];

class Details extends React.Component {
  static propTypes = {
    apiCanvasUpdateStudentEnrollment: PropTypes.shape({
      success: PropTypes.bool,
    }),
    admin: PropTypes.object.isRequired,
    applyDiscountCodeToEnrollment: PropTypes.func.isRequired,
    backfillGrades: PropTypes.func.isRequired,
    assignableCourseInstructors: PropTypes.object.isRequired,
    clearCanvasStudentEnrollmentUpdateFlag: PropTypes.func.isRequired,
    clearEnrollmentRefundEligibility: PropTypes.func.isRequired,
    clearNABCCanvasCoursePoints: PropTypes.func.isRequired,
    closeForceConcludeDialog: PropTypes.func.isRequired,
    courses: PropTypes.object.isRequired,
    coursesOffered: PropTypes.array.isRequired,
    departmentChairCourses: PropTypes.array.isRequired,
    extendStudentQuizTime: PropTypes.func.isRequired,
    fetchAssignableCourseInstructors: PropTypes.func.isRequired,
    fetchCognitoStudentData: PropTypes.func.isRequired,
    fetchCourses: PropTypes.func.isRequired,
    fetchCourseSyllabus: PropTypes.func.isRequired,
    fetchGrades: PropTypes.func.isRequired,
    fetchNABCCanvasCoursePoints: PropTypes.func.isRequired,
    fetchRefundEligibility: PropTypes.func.isRequired,
    fetchUnassignedStudentIds: PropTypes.func.isRequired,
    fetchStudentCourses: PropTypes.func.isRequired,
    fetchPreviousInfoForStudent: PropTypes.func.isRequired,
    forceConcludeStudentCourse: PropTypes.func.isRequired,
    loadingStates: PropTypes.object.isRequired,
    manuallyAssignInstructor: PropTypes.func.isRequired,
    manuallyEnrollStudent: PropTypes.func.isRequired,
    updateCanvasStudentCourseEnrollment: PropTypes.func.isRequired,
    updateTransactionEnrollment: PropTypes.func.isRequired,
    updateStudent: PropTypes.func.isRequired,
    studentsPreviousRecords: PropTypes.object.isRequired,
    studentCognitoInfo: PropTypes.object.isRequired,
    studentForceConcludePoints: PropTypes.shape({
      nabc: PropTypes.shape({
        grade_percentage: PropTypes.string,
        letter_grade: PropTypes.string,
        possible_points: PropTypes.string,
        score: PropTypes.string,
      }),
      canvas: PropTypes.shape({
        grade_percentage: PropTypes.string,
        letter_grade: PropTypes.string,
        possible_points: PropTypes.string,
        score: PropTypes.string,
      }),
      completed: PropTypes.bool,
    }),
    requestRefund: PropTypes.func.isRequired,
    updateEnrollments: PropTypes.func.isRequired,
    studentCourses: PropTypes.object.isRequired,
    studentId: PropTypes.number.isRequired,

    grades: PropTypes.object,
    refundEligibility: PropTypes.object,
    renderCoursesOnly: PropTypes.bool,
    student: PropTypes.object, // @TODO: this is required but may be null until students reducer is filled
  };

  constructor(props) {
    super(props);

    this.state = {
      pageNum: 0,
      rowsPerPage: 10,
      confirmDisableDialogOpen: false,
      confirmWithdrawDialogOpen: false,
      withdrawalGrade: '',
      studentIdToEdit: null,
      studentSettingsDialogOpen: false,

      errorNoNotesText: false,

      displayActiveStudentsOnly: true,
      displayUnassignedStudentsOnly: false,

      // controlled inputs
      searchText: '',
      firstNameText: '',
      middleNameText: '',
      lastNameText: '',
      emailText: '',
      phoneText: '',
      streetAddressText: '',
      streetAddressLineTwoText: '',
      cityText: '',
      stateOrProvinceOption: SELECT_INITIAL_VALUE,
      zipText: '',
      countryOption: SELECT_INITIAL_VALUE,
      schoolNameText: '',
      schoolCityText: '',
      schoolStateOrProvinceOption: SELECT_INITIAL_VALUE,
      studentIsDisabled: '',
      notesText: '',

      addCourseSelectionId: '', // @TODO: warning for going from controlled to uncontrolled when clicking enroll button
      isDatePickerOpen: false,
      isStartDatePickerOpen: false,
      dateDeadlineForClass: 0,
      startDeadline: 0,
      suppressWelcomeEmail: false,
      assignInstructorManually: false,

      isConfirmForceConcludeDialogOpen: false,
      enableConfirmForceConcludeButton: false,
      setCourseAsUnpaid: false,
      displayUnpaidNotes: false,
      unpaidNoteValue: '',
      unpaidNoteError: true,
      displayTranscriptHistoryRequests: false,
      withdrawRequestDate: moment(),
      withdrawRefundNotes: '',
      changeInRegistrationCourse: '',
      refundReason: '',
      idOfInstructorToAssign: '',
      overideRefundEligibility: false,
      forceConcludeNabcHighlighted: true,
      displayDiscountCodeDialog: false,
      courseIdForDiscountCode: '',
      enrollmentId: '',
      displayDialogForMultipleRefundCancelsOnWithdraw: false,
      displayConsortiumGradeChangeDialog: false,
    };
  }

  _getDeptChairCourseIds() {
    if (this.props.departmentChairCourses.length) {
      return this.props.departmentChairCourses.map((course) => {
        return course.id;
      });
    }
    return null;
  }

  componentDidMount() {
    this.props.fetchGrades(this.props.studentId);
    this.props.fetchPreviousInfoForStudent(this.props.studentId);
    this.props.fetchStudentCourses(this.props.studentId, this._getDeptChairCourseIds());
    this.props.fetchCourses();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.studentId !== this.props.studentId) {
      this.props.fetchStudentCourses(this.props.studentId, this._getDeptChairCourseIds());
      this.props.fetchGrades(this.props.studentId);
    }

    if (this.props.apiCanvasUpdateStudentEnrollment.success) {
      this.setState({confirmWithdrawDialogOpen: false});
      this.props.clearCanvasStudentEnrollmentUpdateFlag();
    }

    if (this.props.studentForceConcludePoints.completed) {
      this.setState({isConfirmForceConcludeDialogOpen: false});
      this.props.closeForceConcludeDialog();
      this.props.fetchStudentCourses(this.props.studentId, this._getDeptChairCourseIds());
    }
  }

  _isLessThan18YearsAgo(birthday) {
    const diff = new Date().getTime() - new Date(birthday).getTime();

    return diff < (1000 * 60 * 60 * 24 * 365.25 * 18);
  }

  _confirmWithdrawDialogClose() {
    this.props.clearEnrollmentRefundEligibility(this.state.enrollmentId);
    this.setState({
      confirmWithdrawDialogOpen: false,
      withdrawRequestDate: null,
      refundReason: '',
      refundReasonOtherText: '',
      changeInRegistrationCourse: '',
      enrollmentId: null,
      changeInRegistrationDiscountCode: '',
      overideRefundEligibility: false,
    });
  }

  _renderUnwithdrawSnackBar() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.displayDialogForMultipleRefundCancelsOnWithdraw}
        onClose={() => this.setState({displayDialogForMultipleRefundCancelsOnWithdraw: false})}
      >
        <MuiAlert severity={'error'} variant='standard'>
          <div style={styles.alertContainer}>
            <p id='message-id'>Error: This enrollment has multiple refund requests. Please contact Engineering to cancel this refund.</p>
            <IconButton
              key='close'
              onClick={() => this.setState({displayDialogForMultipleRefundCancelsOnWithdraw: false})}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </MuiAlert>
      </Snackbar>
    );
  }

  _refundMessage(c, isEligible, isChangeInRegistration, hasChangeRegistrationErrorMessage) {
    let message = 'Not eligible for a refund';
    if (isEligible) {
      if (!c.date_assigned_instructor || (new Date(moment.utc(this.state.withdrawRequestDate).format()).getTime() - new Date(c.date_assigned_instructor).getTime()) / 1000 <= 86400) {
        message = 'The student will be refunded the full course amount';
      } else {
        message = 'The student will be refunded the full course amount minus $50';
      }
    }

    if (isChangeInRegistration && this.state.changeInRegistrationCourse) {
      if (!hasChangeRegistrationErrorMessage || this.state.overideRefundEligibility) {
        const currentCourse = this.props.coursesOffered.find((course) => course.id === c.course_id);
        const courseToChangeInto = this.props.coursesOffered.find((course) => course.id === this.state.changeInRegistrationCourse);
        const priceDifference = this._calculateChangeInRegistrationPriceDifference(c.course_id, this.state.changeInRegistrationCourse);
        const courseToChangeIntoCreditsMessage = !courseToChangeInto.credits ? 'NursingABC' : `${courseToChangeInto.credits} credit`;
        const currentCourseCreditsMessage = !currentCourse.credits ? 'NursingABC' : `${currentCourse.credits} credit`;
        if (priceDifference > 0) {
          message = <p>The student will receive a discount code because they are changing from a {currentCourseCreditsMessage} course to {courseToChangeIntoCreditsMessage} course<br /><br />Their discount code will be {this.state.changeInRegistrationDiscountCode}</p>;
        } else if (isEligible) {
          if (priceDifference === 0) {
            message = `The student will not receive a refund because they are changing from a ${currentCourseCreditsMessage} course to ${courseToChangeIntoCreditsMessage} course`;
          } else if (priceDifference < 0) {
            message = `The student will receive a refund because they are changing from a ${currentCourseCreditsMessage} course to ${courseToChangeIntoCreditsMessage} course`;
          }
        } else {
          message = 'The student is outside of refund policy so they will not receive a refund but will be changed to the new course';
        }
      } else {
        return hasChangeRegistrationErrorMessage;
      }
    }
    return message;
  }

  _calculateChangeInRegistrationPriceDifference(currentCourseId, courseIdToChangeInto) {
    const courseToChangeInto = this.props.coursesOffered.find((course) => course.id === courseIdToChangeInto);
    const currentCourse = this.props.coursesOffered.find((course) => course.id === currentCourseId);
    return courseToChangeInto.price - currentCourse.price;
  }

  _disableConfirmWithdraw(courseId) {
    if (this.state.overideRefundEligibility && this.props.refundEligibility[courseId]) {
      return false;
    }
    if (this.state.refundReason === CHANGE_IN_REGISTRATION && this.props.refundEligibility[courseId]) {
      return !!this.props.refundEligibility[courseId].has_change_registration_error_message;
    }
    return !this.props.refundEligibility[courseId];
  }

  _generateAuthCode() {
    return `${Math.random()}`.substring(2, 9);
  }

  _createRefund(enrollmentId, courseId = null, discountCode, callback) {
    let reason = this.state.refundReason;
    if (this.state.refundReason === OTHER) {
      reason = this.state.refundReasonOtherText;
    }
    if (this.state.refundReason === CHANGE_IN_REGISTRATION) {
      reason = `${this.state.refundReason}from:${courseId}to:${this.state.changeInRegistrationCourse}`;
    }
    this.props.requestRefund(
      enrollmentId,
      reason,
      this.state.changeInRegistrationCourse,
      this.state.withdrawRefundNotes,
      moment.utc(this.state.withdrawRequestDate).format('YYYY-M-DD HH:mm:ss'),
      discountCode,
      this.state.overideRefundEligibility,
      callback
    );
  }

  _getErrorKey(name) {
    return `${name}Error`;
  }

  _renderStudentInfo(student) {
    const {
      previousNames,
      previousAddresses,
      previousEmails,
      previousPhoneNumbers,
      previousSchoolOfIntent,
      previousNotes,
    } = this._getPreviousInfoByGroup(student);

    return (
      <div style={styles.detailsWrapper}>
        <span style={styles.nameCakeWrapper}>
          {this._renderField('Name', <span>{this._formatName(student.first_name, student.middle_name, student.last_name)} {this._isLessThan18YearsAgo(this.props.student.birthday) && <CakeIcon style={styles.under18Icon} />}</span>, (previousNames.length === 0 ? null : this._renderPreviousInfo(previousNames, 'names')))}
          {student.side_view_camera_required === 1 && <p style={styles.sideViewRequired}><CameraEnhanceOutlineIcon style={styles.cameraIcon} />Side view camera is required.</p>}
        </span>
        {this._renderField('Email', student.email, (previousEmails.length === 0 ? null : this._renderPreviousInfo(previousEmails, 'emails')))}
        {this._renderField('Phone', getFormattedPhone(student.phone), (previousPhoneNumbers.length === 0 ? null : this._renderPreviousInfo(previousPhoneNumbers, 'phones')))}
        {this.state.showMoreDetails &&
          <>
            {this._renderField('Street address', this._formatAddress(student.line_1, student.line_2, student.city, student.state, student.zip_code, student.country), (previousAddresses.length === 0 ? null : this._renderPreviousInfo(previousAddresses, 'addresses')))}
            {this._renderField('Birthday', moment.utc(student.birthday).format(PRETTY_DATE_FORMAT))}
            {this._renderField('Military', student.military === 1 ? 'Yes' : 'No')}
            <Tooltip title={moment(student.created_at).format('LT')}>
              {this._renderField('Account creation date', moment(student.created_at).format(PRETTY_DATE_FORMAT))}
            </Tooltip>
            <Tooltip title={moment(student.last_logged_date).format('LT')}>
              {this._renderField('Most recent log in date', moment(student.last_logged_date).format(PRETTY_DATE_FORMAT))}{/* @TODO: add time too */}
            </Tooltip>
            {this._renderField('School of intent', this._formatSchoolOfIntent(student.school, student.school_city, student.school_state, student.program), previousSchoolOfIntent.length === 0 ? null : this._renderPreviousInfo(previousSchoolOfIntent, 'School of Intent'))}
            {this._renderField('Student ID', student.id)}
            {this._renderField('Canvas ID', <a target='_blank' rel='noopener noreferrer' href={`${Globals.canvasUrl}/accounts/1/users/${student.canvas_id}`}>{student.canvas_id}</a>)}
            {this._renderField('Infusionsoft ID', <a target='_blank' rel='noopener noreferrer' href={`https://zi166.infusionsoft.com/app/searchResults/searchResults?searchTerm=${student.email}`}>Go to Infusionsoft</a>)}
            {this._renderField('Notes', (previousNotes.length === 0 ? null : this._renderPreviousInfo(previousNotes, 'notes')))}
            {!!this.props.admin.perms[DEVELOPER] && this._renderField('Cognito ID', <a target='_blank' rel='noopener noreferrer' href={`https://us-east-1.console.aws.amazon.com/cognito/v2/idp/user-pools/us-east-1_t1KZiFDjT/users/details/${student.cognito_id}?region=us-east-1`}>{student.cognito_id}</a>)}
            {/* @TODO: add notes section? would be better if we can add a link to salesforce notes */}
          </>
        }
        <div style={styles.detailsButtonWrapper}>
          <Button
            onClick={() => this.setState((prevState) => {
              return {showMoreDetails: !prevState.showMoreDetails};
            })}
            color='secondary'
          >
            {this.state.showMoreDetails ? 'Less' : 'More'}
          </Button>
        </div>
      </div>
    );
  }

  _renderStudentCourses(student) {
    return (
      <div style={styles.studentCoursesWrapper}>
        <div>
          {this._renderCoursesInfo(student)}
          {this.state.isAddCourseVisible && <ManualEnrollment studentId={this.props.studentId} onClose={() => this.setState({isAddCourseVisible: false})} />}
        </div>
        <div style={styles.addCourseAndTranscriptHistoryButtonsWrapper}>
          {(!!this.props.admin.perms[EDIT_STUDENT_INFO] && !this.state.isAddCourseVisible && !this.props.renderCoursesOnly) &&
            <Button
              onClick={() => {
                this.setState({isAddCourseVisible: true});
              }}
              variant='contained'
              color='secondary'
            >
              Add course
            </Button>
          }
          {(!!this.props.admin.perms[READ_STUDENT_INFO] && !this.state.isAddCourseVisible && this.props.admin.department !== Globals.userDepartments.facultyDepartmentChair) &&
            <Button
              style={styles.transcriptHistoryRequestButton}
              variant='contained'
              color='secondary'
              onClick={() => this.setState({displayTranscriptHistoryRequests: true})}
            >
              Transcript Request History
            </Button>
          }
        </div>
      </div>
    );
  }

  _renderConsortiumGradeChangeDialog() {
    return (
      <Dialog
        open={true}
        onClose={() => this.setState({displayConsortiumGradeChangeDialog: false})}
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle id='alert-dialog-consortium-dialog-title'>Grade Change Error</DialogTitle>
        <DialogContent>
          <div>
            <p>This student is a consortium student, please contact <a href='mailto:studentsuccess@portagelearning.edu'>Student Success</a> for a grade change.</p>
          </div>
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              color='secondary'
              onClick={() => this.setState({displayConsortiumGradeChangeDialog: false})}
            >
                OK
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }

  _renderTranscriptHistoryDialog(student) {
    return (
      <Dialog
        open={true}
        onClose={() => this.setState({displayTranscriptHistoryRequests: false})}
        fullWidth={true}
        maxWidth='md'
      >
        <DialogTitle id='alert-dialog-transcript-history-title'>Transcript Request History</DialogTitle>
        <DialogContent>
          <div>
            <TranscriptHistory studentId={student.id} />
          </div>
        </DialogContent>
        <DialogActions>
          <div style={styles.transcriptHistoryDialogCloseButton}>
            <Button
              color='secondary'
              onClick={() => this.setState({displayTranscriptHistoryRequests: false})}
            >
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }

  _renderStudentSettingsDialog(student) {
    return (
      <Dialog
        open={true}
        onClose={() => this.setState({studentSettingsDialogOpen: false})}
        onEnter={() => this.props.fetchCognitoStudentData(student.id)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Student settings</DialogTitle>
        <DialogContent style={styles.innerDialogContent}>
          {!!this.props.admin.perms[EDIT_STUDENT_INFO] &&
            <div style={styles.settingsSection}>
              <h4>Canvas</h4>
              <div style={styles.settingsRow}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={student.extend_quiz_time}
                      onChange={() => this.props.extendStudentQuizTime(student.canvas_id, student.extend_quiz_time ? 0 : 1)}
                    />
                  }
                  label='Time accommodations'
                  style={styles.muiFormControlLabelCustom}
                />
                {this.props.loadingStates.extendStudentQuizTime && <CircularProgress size={30} />}
              </div>
              {(this.props.admin.perms[SIDE_VIEW_CAMERA] || this.props.admin.perms[DEVELOPER]) &&
                <>
                  <h4>Student Flags</h4>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => this.props.updateStudent({
                      id: student.id,
                      side_view_camera_required: this.props.student.side_view_camera_required ? 0 : 1,
                      notes: 'Updating side view camera required',
                    })}
                  >
                    MARK SIDE-VIEW CAMERA REQUIRED
                  </Button>
                </>
              }
            </div>
          }
        </DialogContent>
        <DialogActions>
          {(!!this.props.admin.perms[DEVELOPER] || !!this.props.admin.perms[DISABLE_STUDENT]) &&
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                if (!this.props.studentCognitoInfo.Enabled) {
                  this.props.updateStudent({
                    id: student.id,
                    disabled: 0,
                    notes: 're-enabled',
                  }, () => this.props.fetchCognitoStudentData(this.props.studentId));
                } else {
                  this.setState({confirmDisableDialogOpen: true});
                }
              }}
            >
              {`${this.props.studentCognitoInfo.Enabled ? 'Disable' : 'Enable'} Login`}
            </Button>
          }
          {this.state.confirmDisableDialogOpen &&
            <Dialog
              open={true}
              onClose={() => this.setState({confirmDisableDialogOpen: false})}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogTitle id='alert-dialog-title'>Disable student?</DialogTitle>
              <DialogContent style={styles.innerDialogContent}>
                {`Are you sure you want to disable this student?  No student data will be affected, but ${student.first_name} no longer will be able to log in to any NursingABC or Portage Learning service.`}
                <TextField
                  style={{...styles.textField, width: '100%'}}
                  label='Reason'
                  error={this.state.errorNoNotesText}
                  placeholder='Reason for disabling student'
                  value={this.state.notesText}
                  onChange={(e) => this.setState({notesText: e.target.value, errorNoNotesText: false})}
                  margin='dense'
                  required={true}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    if (!this.state.notesText) {
                      this.setState({errorNoNotesText: true});
                      return;
                    }
                    this.props.updateStudent({
                      id: student.id,
                      disabled: 1,
                      notes: this.state.notesText,
                    }, () => this.props.fetchCognitoStudentData(this.props.studentId));
                    this.setState({
                      confirmDisableDialogOpen: false,
                      notesText: '',
                    });
                  }}
                >
                  Confirm disable
                </Button>
                <Button
                  color='secondary'
                  onClick={() => this.setState({confirmDisableDialogOpen: false})}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          }
          {this.props.loadingStates.resetStudentPassword && <CircularProgress size={30} />}
          <span style={styles.expand}></span>
          <Button
            onClick={() => this.setState({studentSettingsDialogOpen: false})}
            color='secondary'
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _renderData(label, value, onEditClick) {
    return (
      <Grid
        style={styles.dataItemWrapper}
        item
        md={3}
        sm={6}
        xs={12}
      >
        <div style={styles.dataItemInnerWrapper}>
          <span style={styles.dataItemLabel}>{label}</span>
          <span style={styles.dataItemValue}>{value}</span>
        </div>
        {onEditClick &&
          <div style={styles.deadlineWrapper}>
            <IconButton onClick={() => onEditClick()}>
              <EditIcon />
            </IconButton>
          </div>
        }
      </Grid>
    );
  }

  _displayEnrollmentAmountPaid(enrollmentTransactions) {
    const transactions = enrollmentTransactions.transactions.filter((et) => et.payment_type === 'payment');
    return (transactions[0]) ? `${formatNumberAsUScurrency(transactions[0].amount)}` : '-';
  }

  _displayEnrollmentDiscountCode(enrollmentTransactions) {
    const transactions = enrollmentTransactions.transactions.filter((et) => et.payment_type === 'payment');
    return transactions[0]?.discount ? `${transactions[0].discount.code}` : '-';
  }

  _displayEnrollmentPaymentProcessor(enrollmentTransactions) {
    const paymentProcessor = enrollmentTransactions.transactions.filter((et) => et.payment_type === 'payment');
    if (paymentProcessor[0]?.payment_processor === 'braintree') {
      return <a href={`https://www.braintreegateway.com/merchants/yryksm7vjfz3t554/transactions/${paymentProcessor[0].transaction_id}`} target='_blank' rel='noopener noreferrer'>braintree ({paymentProcessor[0].transaction_id})</a>;
    } else if (paymentProcessor[0]?.payment_processor === 'pen') {
      return 'pen';
    }
    return '-';
  }

  // _hasOpenRefunds(enrollmentTransactions) {
  //   return enrollmentTransactions.transactions.some((t) => t.payment_type === 'refund' && t.status === 'open');
  // }

  _displayEnrollmentRefunds(enrollmentTransactions) {
    const refundTransactions = enrollmentTransactions.transactions.filter((et) => et.payment_type === 'refund');
    return (
      <Grid
        style={styles.dataItemWrapper}
        item
        md={3}
        sm={6}
        xs={12}
      >
        <div>
          <div style={styles.dataItemInnerWrapper}>
            <span style={styles.dataItemLabel}>Refunds</span>
          </div>
          {refundTransactions.length === 0
            ? <div>-</div>
            : <List styles={{padding: 0}}>
              {refundTransactions.map((refund) => {
                return (
                  <React.Fragment key={refund.id}>
                    <Tooltip title={<div>{refund?.notes}  <br /> {moment(refund.updated_at).format('L')}</div>}><ListItem style={styles.listDataItemValue}>{formatNumberAsUScurrency(refund.amount)} - {refund.status}</ListItem></Tooltip>
                  </React.Fragment>
                );
              })}
            </List>
          }
        </div>
      </Grid>
    );
  }

  _shouldRenderAssignInstructor(course) {
    if (course.withdrawn) {
      return false;
    }

    if (!!this.props.admin.perms[MANUALLY_ASSIGN_INSTRUCTOR] && course.instructor_name === 'unassigned' && !course.change_of_previous_enrollment_id) {
      return true;
    }

    return !!(course.change_of_previous_enrollment_id && course.paid_in_full && course.instructor_name === 'unassigned');
  }

  _renderCoursesInfo(student) {
    if (this.props.studentCourses[this.props.studentId] && this.props.grades[this.props.studentId]) {
      return (
        <div style={styles.coursesWrapper}>
          {this.props.studentCourses[this.props.studentId].map((c) => {
            let changeOfRegistrationText = '';
            const finalGrade = c.final_letter_grade || '-';
            let status = 'active';
            let panelSummaryStyle = {};
            if (c.open_for_review) {
              status = 'openForReview';
            } else if (c.open_for_grade_change) {
              status = 'openForGradeChange';
            } else if (c.complete) {
              status = 'completed';
            } else if (c.withdrawn) {
              if (c.final_letter_grade === 'NG') {
                panelSummaryStyle = styles.ngOverlay;
                status = 'ng';
              } else {
                status = 'withdrawn';
              }
            }
            if (c.change_of_previous_enrollment_id) {
              changeOfRegistrationText = `(Change in registration from ${this.props.studentCourses[this.props.studentId].find((e) => e.id === c.change_of_previous_enrollment_id).name})`;
            }
            const studentCourseForChangeOfEnrollment = this.props.studentCourses[this.props.studentId].find((e) => e.change_of_previous_enrollment_id === c.id);
            if (studentCourseForChangeOfEnrollment) {
              changeOfRegistrationText = `(Change in registration to ${studentCourseForChangeOfEnrollment.name})`;
            }
            const menuItems = [];
            if (this._shouldRenderAssignInstructor(c)) {
              menuItems.push(
                <MenuItem
                  key='assignInstructor'
                  onClick={(event) => {
                    this.setState({
                      assignInstructorDialogIsOpen: true,
                      enrollmentId: c.id,
                    });
                    this.props.fetchAssignableCourseInstructors(c.course_id);
                  }}
                >
                  Assign instructor
                </MenuItem>
              );
            }
            if (!!this.props.admin.perms[EDIT_STUDENT_INFO] && c.complete === 0 && c.withdrawn === 0) {
              menuItems.push(
                <MenuItem
                  key='withdraw'
                  onClick={() => this.setState({confirmWithdrawDialogOpen: true, enrollmentId: c.id})}
                >
                  Withdraw
                </MenuItem>
              );
            }
            // if (c.final_letter_grade !== 'NG' && c.withdrawn === 1 && !this.props.studentCourses[this.props.studentId].find((course) => course.change_of_previous_enrollment_id === c.id) && !c.open_for_grade_change && !c.open_for_review) {
            //   if (!!this.props.admin.perms[DEVELOPER] || !!this.props.admin.perms[UNWITHDRAW_ENROLLMENT]) {
            //     menuItems.push(
            //       <MenuItem
            //         key='unwithdraw'
            //         onClick={() => {
            //           const inPolicy = ['WP', 'WX', 'WF'].includes(c.final_letter_grade) && moment(c.date_concluded) >= moment().subtract(3, 'months');
            //           if (inPolicy || window.confirm('It looks like this unwithdraw is significantly out of policy.  If you would like to proceed anyways, please press "OK".  To cancel this request, press "Cancel".')) {
            //             this.props.updateCanvasStudentCourseEnrollment({
            //               studentId: this.props.studentId,
            //               unwithdraw: 1,
            //               enrollmentId: c.id,
            //             });
            //           }
            //         }}
            //       >
            //         Unwithdraw
            //       </MenuItem>
            //     );
            //   }
            // }
            // if (c.final_letter_grade === 'NG' && this._hasOpenRefunds(c.transactions_enrollments) && !this.props.studentCourses[this.props.studentId].find((course) => course.change_of_previous_enrollment_id === c.id) && !c.open_for_grade_change) {
            //   if (!!this.props.admin.perms[DEVELOPER] || !!this.props.admin.perms[UNWITHDRAW_ENROLLMENT]) {
            //     menuItems.push(
            //       <MenuItem
            //         key='unwithdrawAndCancelRefund'
            //         onClick={() => {
            //           const updateEnrollmentValues = {
            //             studentId: this.props.studentId,
            //             unwithdraw: 1,
            //             enrollmentId: c.id,
            //             openRefundId: [],
            //           };
            //           if (c.refunds.length === 1) {
            //             updateEnrollmentValues.openRefundId = c.refunds;
            //           } else if (c.refunds.length > 1) {
            //             this.setState({displayDialogForMultipleRefundCancelsOnWithdraw: true});
            //             return;
            //           }
            //           this.props.updateCanvasStudentCourseEnrollment(updateEnrollmentValues);
            //         }}
            //       >
            //         Unwithdraw and cancel refund
            //       </MenuItem>
            //     );
            //   }
            // }
            if (!!this.props.admin.perms[DEVELOPER] || !!this.props.admin.perms[PAID_IN_FULL]) {
              menuItems.push(
                <MenuItem
                  key='markAsPaidUnpaid'
                  onClick={() => {
                    this.props.updateTransactionEnrollment(c.id, {is_paid_in_full: +!c.paid_in_full});
                  }}
                  variant='contained'
                  color='primary'
                >
                  {c.paid_in_full ? 'Mark as "unpaid"' : 'Mark as "paid"'}
                </MenuItem>
              );
            }
            if (!!this.props.admin.perms.developer && c.complete === 0 && c.withdrawn === 0) {
              menuItems.push(
                <MenuItem
                  key='forceConclude'
                  onClick={() => {
                    this.setState({
                      devMenuAnchorEl: null, isConfirmForceConcludeDialogOpen: true, enrollmentId: c.id,
                    });
                    this.props.fetchNABCCanvasCoursePoints(this.props.studentId, this.state.enrollmentId);
                  }}
                >
                  Force conclude
                </MenuItem>
              );
            }
            if (!!this.props.admin.perms[APPLY_DISCOUNT] && !c.transactions_enrollments.transactions[0]?.discount) {
              menuItems.push(
                <MenuItem
                  key='displayDiscountCodeDialog'
                  onClick={() => this.setState({
                    displayDiscountCodeDialog: true,
                    courseIdForDiscountCode: c.course_id,
                    enrollmentId: c.id,
                  })}
                >
                  Apply authorization code
                </MenuItem>
              );
            }
            if ((c.final_letter_grade === null || this.props.admin.perms[DEVELOPER]) && !!this.props.admin.perms[GRADES]) {
              menuItems.push(
                <MenuItem
                  key='backfillGrades'
                  onClick={() => {
                    this.props.backfillGrades(c.id, c.student_id);
                    this.setState({devMenuAnchorEl: null});
                  }}
                >
                  Sync grades
                </MenuItem>
              );
            }
            if ((c.original_date_concluded && c.final_letter_grade !== 'NG' && c.final_letter_grade !== null && !c.open_for_grade_change && !c.open_for_review && !moment().isAfter(moment(c.original_date_concluded).add(14, 'days')) && this.props.admin.perms[RESTORE_ENROLLMENT]) || (c.final_letter_grade !== null && c.final_letter_grade !== 'NG' && this.props.admin.perms[RESTORE_ENROLLMENT] && this.props.admin.department !== Globals.userDepartments.facultyDepartmentChair && !c.open_for_grade_change && !c.open_for_review)) {
              menuItems.push(
                <MenuItem
                  key='restoreForReview'
                  onClick={() => {
                    this.props.updateEnrollments(c.id, {open_for_review: 1, course_ids: this._getDeptChairCourseIds()});
                    this.setState({devMenuAnchorEl: null});
                  }}
                >
                  Restore Enrollment For Review
                </MenuItem>
              );
            }
            if (c.date_concluded && c.final_letter_grade !== 'NG' && c.open_for_review && this.props.admin.perms[RESTORE_ENROLLMENT]) {
              menuItems.push(
                <MenuItem
                  key='concludeForReview'
                  onClick={() => {
                    this.props.updateEnrollments(c.id, {open_for_review: 0, course_ids: this._getDeptChairCourseIds()});
                    this.setState({devMenuAnchorEl: null});
                  }}
                >
                  Conclude Enrollment For Review
                </MenuItem>
              );
            }
            if ((c.original_date_concluded && c.final_letter_grade !== 'NG' && c.final_letter_grade !== null && !c.open_for_review && !c.open_for_grade_change && !moment().isAfter(moment(c.original_date_concluded).add(14, 'days')) && this.props.admin.perms[RESTORE_ENROLLMENT] && !c.instructor_changed_grade_after_completion) || (c.final_letter_grade !== null && c.final_letter_grade !== 'NG' && this.props.admin.perms[RESTORE_ENROLLMENT] && this.props.admin.department !== Globals.userDepartments.facultyDepartmentChair && !c.open_for_review && !c.open_for_grade_change)) {
              menuItems.push(
                <MenuItem
                  key='restoreForGradeChange'
                  onClick={() => {
                    if (c.institution_campus_partner_agreement_id && this.props.admin.department === Globals.userDepartments.facultyDepartmentChair) {
                      this.setState({displayConsortiumGradeChangeDialog: true});
                    } else {
                      this.props.updateEnrollments(c.id, {
                        open_for_grade_change: 1,
                        date_deadline: moment(c.date_deadline).add(21, 'days').unix(),
                        course_ids: this._getDeptChairCourseIds(),
                      });
                    }
                    this.setState({devMenuAnchorEl: null});
                  }}
                >
                  Restore Enrollment For Grade Change
                </MenuItem>
              );
            }
            if (c.original_date_concluded && c.final_letter_grade !== 'NG' && c.open_for_grade_change && this.props.admin.perms[RESTORE_ENROLLMENT]) {
              menuItems.push(
                <MenuItem
                  key='concludeForGradeChange'
                  onClick={() => {
                    this.props.updateEnrollments(c.id, {
                      open_for_grade_change: 0,
                      conclude_for_grade_change: 1,
                      course_ids: this._getDeptChairCourseIds(),
                    });
                    this.setState({devMenuAnchorEl: null});
                  }}
                >
                  Conclude Enrollment For Grade Change
                </MenuItem>
              );
            }
            if (c.open_for_grade_change && this.props.admin.perms[RESTORE_ENROLLMENT]) {
              menuItems.push(
                <MenuItem
                  key='cancleGradeChange'
                  onClick={() => {
                    this.props.updateEnrollments(c.id, {
                      open_for_grade_change: 0,
                      date_deadline: moment(c.date_started).add(1, 'years').unix(),
                      course_ids: this._getDeptChairCourseIds(),
                    });
                    this.setState({devMenuAnchorEl: null});
                  }}
                >
                    Cancel grade change request
                </MenuItem>
              );
            }

            return (
              <Accordion key={c.id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <span key={c.id} style={{...styles.expansionPanelSummaryInner, ...panelSummaryStyle}}>
                    {<span style={{...styles.statusWrapper, backgroundColor: Globals.colors.courseStatuses[c.instructor_name === 'unassigned' ? 'unassigned' : status]}}></span>}
                    <div style={styles.courseHeader}>
                      <div style={styles.enrollmentTitle}>
                        <span style={styles.courseName}>{c.name}</span>
                        {changeOfRegistrationText && <span style={styles.courseChangeOfRegistration}><i>{changeOfRegistrationText}</i></span>}
                        {!!c.has_violation && <HasViolation severity={c.has_violation} />}
                        {c.partner_school && <Tooltip title={c.partner_school}><FlagIcon style={{color: Globals.colors.primary}} /></Tooltip>}
                        <div style={{color: Globals.colors.courseStatuses.openForReview}}>
                          {!!c.open_for_review && <p> Open for review</p>}
                          {!!c.open_for_grade_change && <p> Open for grade change</p>}
                        </div>
                      </div>
                      <span style={styles.courseInstructor}>{c.instructor_name}</span>
                    </div>
                  </span>
                </AccordionSummary>
                <AccordionDetails style={styles.panelDetails}>
                  <Grid container style={styles.dataWrapper}>
                    {this._renderData('Status', status)}
                    {this._renderData('Registration date', moment(c.created_at).format('L LT'))}
                    {this._renderData(
                      'Start date',
                      c.date_started ? (
                        <span style={styles.startDateWrapper}>
                          {moment(c.date_started).format('L LT')}
                          {!!c.force_started && <SportsScoreIcon style={styles.forceStartIcon} />}
                        </span>
                      ) : '-'
                    )}
                    {c.date_concluded
                      ? this._renderData(`${c.withdrawn ? 'Withdraw' : 'Completion'} date`, moment(c.date_concluded).format('L LT'))
                      : this._renderData('Completion date', '-')
                    }
                    {!!this.props.admin.perms[GRADES] && this._renderData('Final grade', finalGrade)}
                    {this._renderData('Completion date deadline',
                      c.date_deadline ? moment(c.date_deadline).format('L') : '-',
                      ((c.complete === 0 && c.withdrawn === 0) || (c.open_for_grade_change === 1 && ['WX', 'WP', 'WF'].includes(c.final_letter_grade))) ? () => {
                        this.setState({isDatePickerOpen: true, enrollmentId: c.id});
                      } : undefined)}
                    {this._renderData('Start date deadline',
                      c.start_deadline ? moment(c.start_deadline).format('L') : '-',
                      (c.complete === 0 && c.withdrawn === 0 && !c.date_started) ? () => {
                        this.setState({isStartDatePickerOpen: true, enrollmentId: c.id});
                      } : undefined)}
                    {this._renderData('Paid', c.paid_in_full ? <CheckIcon color={Globals.colors.green} size={25} /> : <CloseIcon style={{fill: Globals.colors.primary}} size={14} />)}
                    {c.notes && this._renderData('Notes', c.notes)}
                    {this.state.isDatePickerOpen && c.id === this.state.enrollmentId &&
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          open={true}
                          value={moment(c.date_deadline)}
                          style={styles.datePicker}
                          onChange={(e, value) => this.setState({dateDeadlineForClass: e.unix()})}
                          onClose={() => this.setState({isDatePickerOpen: false})}
                          onAccept={(e) => this.props.updateEnrollments(c.id, {date_deadline: (e.unix()) + moment(c.date_deadline || 0).seconds(), course_ids: this._getDeptChairCourseIds()})}
                        />
                      </MuiPickersUtilsProvider>
                    }
                    {this.state.isStartDatePickerOpen && c.id === this.state.enrollmentId &&
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          open={true}
                          value={c.start_deadline ? c.start_deadline : moment()}
                          style={styles.datePicker}
                          onChange={(e, value) => {}}
                          onClose={() => this.setState({isStartDatePickerOpen: false})}
                          onAccept={(e) => this.props.updateEnrollments(c.id, {start_deadline: (e.unix() + e.seconds())})}
                        />
                      </MuiPickersUtilsProvider>
                    }
                    <Tooltip
                      disableFocusListener={!this.props.admin.perms[DEVELOPER]}
                      disableHoverListener={!this.props.admin.perms[DEVELOPER]}
                      title={<div>Section id: {c.section_id}</div>}
                    >
                      {this._renderData('Enrollment ID', c.id)}
                    </Tooltip>
                    {c.complete === 1 && this._renderData('Course Eval.', c.taken_evaluation ? <CheckIcon color={Globals.colors.green} size={25} /> : <CloseIcon style={{fill: Globals.colors.primary}} size={14} />)}
                    {this._renderData('Amount Paid', this._displayEnrollmentAmountPaid(c.transactions_enrollments))}
                    {this._renderData('Discount Code', this._displayEnrollmentDiscountCode(c.transactions_enrollments))}
                    {this._displayEnrollmentRefunds(c.transactions_enrollments)}
                    {!!this.props.admin.perms[FINANCE] && this._renderData('Payment Processor', this._displayEnrollmentPaymentProcessor(c.transactions_enrollments))}
                    {this._renderData('Has completed grade change', c.has_grade_change ? <CheckIcon color={Globals.colors.green} size={25} /> : <CloseIcon style={{fill: Globals.colors.primary}} size={14} />)}
                  </Grid>
                  <div style={styles.container}>
                    <Grid container>
                      {/* Restore is now only for developers, Admins will still have the ability to withdraw  */}
                      {((!!this.props.admin.perms[DEVELOPER] && c.withdrawn === 0) || (!!this.props.admin.perms[EDIT_STUDENT_INFO] && c.withdrawn === 0)) &&
                        <Grid item xs={3}>
                          {/* @TODO: this should be cleaned up and all dialogs should be abstracted to other components */}
                          {(c.complete === 0) &&
                            <div style={styles.actions}>
                              {this.state.confirmWithdrawDialogOpen && c.id === this.state.enrollmentId &&
                                <Dialog
                                  open={true}
                                  onClose={() => this._confirmWithdrawDialogClose()}
                                  aria-labelledby='alert-dialog-title'
                                  aria-describedby='alert-dialog-description'
                                >
                                  <DialogTitle id='alert-dialog-title'>Withdraw Student</DialogTitle>
                                  <DialogContent style={styles.innerDialogContent}>
                                    <p>{`Are you sure you want to withdraw ${student.first_name} from ${c.name}?`}</p>
                                    <div style={styles.formRow}>
                                      <FormControl component='fieldset'>
                                        <FormLabel component='legend'>Date Withdraw was Requested</FormLabel>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                          <DateTimePicker
                                            value={this.state.withdrawRequestDate}
                                            variant='inline'
                                            label='Date Withdraw was Requested'
                                            disabled={!!this.props.refundEligibility[c.id]}
                                            minDate={c.created_at}
                                            onChange={(e) => {
                                              this.setState({withdrawRequestDate: e});
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </FormControl>
                                    </div>
                                    <div style={styles.formRow}>
                                      <FormControl style={styles.formControlRoot} error={!!this.state[this._getErrorKey('refundReason')]}>
                                        <InputLabel style={styles.inputLabel} htmlFor='refundReason' shrink={true}>Reason for Withdraw</InputLabel>
                                        <Select
                                          style={{...styles.formControl, ...styles.textField}}
                                          value={this.state.refundReason}
                                          disabled={!!this.props.refundEligibility[c.id]}
                                          onChange={(e) => this.setState({refundReason: e.target.value, [this._getErrorKey('refundReason')]: ''})}
                                          input={<Input id='refundReason' name='refundReason' style={styles.TODO} />}
                                        >
                                          <MenuItem key='placeholder' value={SELECT_INITIAL_VALUE} disabled={true}>Reasons*</MenuItem>
                                          {(c.has_payments && !c.change_of_previous_enrollment_id) &&
                                            <MenuItem value={CHANGE_IN_REGISTRATION}>{CHANGE_IN_REGISTRATION}</MenuItem>
                                          }
                                          {REFUND_REASONS.map((reason) => {
                                            return <MenuItem key={reason} value={reason}>{reason}</MenuItem>;
                                          })}
                                          <MenuItem value={OTHER}>{OTHER}</MenuItem>
                                        </Select>
                                        <FormHelperText>{this.state[this._getErrorKey('refundReason')]}</FormHelperText>
                                      </FormControl>
                                      {this.state.refundReason === OTHER &&
                                        <FormControl style={styles.formControlRoot} error={!!this.state[this._getErrorKey('refundReasonOtherText')]}>
                                          <TextField
                                            style={{...styles.textField, width: '100%'}}
                                            label=''
                                            error={!!this.state[this._getErrorKey('refundReasonOtherText')]}
                                            placeholder='Reason'
                                            disabled={this.props.refundEligibility[c.id]}
                                            value={this.state.refundReasonOtherText}
                                            onChange={(e) => this.setState({refundReasonOtherText: e.target.value, errorRefundReasonOtherText: false})}
                                            margin='dense'
                                            required={true}
                                          />
                                          <FormHelperText>{this.state[this._getErrorKey('refundReasonOtherText')]}</FormHelperText>
                                        </FormControl>
                                      }
                                      {this.state.refundReason === CHANGE_IN_REGISTRATION &&
                                        <FormControl style={styles.formControlRoot} error={!!this.state[this._getErrorKey('courseChangeInRegistration')]}>
                                          <InputLabel style={styles.inputLabel} htmlFor='courseChangeInRegistration' shrink={true}>Course To Register In</InputLabel>
                                          <Select
                                            style={{...styles.formControl, ...styles.textField}}
                                            value={!this.state.changeInRegistrationCourse ? '' : this.state.changeInRegistrationCourse}
                                            disabled={!!this.props.refundEligibility[c.id]}
                                            onChange={(e) => this.setState({
                                              changeInRegistrationCourse: e.target.value,
                                              changeInRegistrationDiscountCode: this._calculateChangeInRegistrationPriceDifference(c.course_id, e.target.value) <= 0 ? '' : this._generateAuthCode(),
                                            })}
                                            input={<Input id='courseChangeInRegistration' name='courseChangeInRegistration' />}
                                          >
                                            {[
                                              <MenuItem key='placeholder' value={SELECT_INITIAL_VALUE} disabled={true}>Course*</MenuItem>,
                                              this.props.coursesOffered.filter((c) => !c.disabled && !(this.props.studentCourses[this.props.studentId].filter((c) => !c.final_letter_grade).map((c) => c.course_id).includes(c.id))).map((course) => <MenuItem key={course.id} value={course.id}>{`${course.code}: ${course.title}`}</MenuItem>),
                                            ]}
                                          </Select>
                                          <FormHelperText>{this.state[this._getErrorKey('courseChangeInRegistration')]}</FormHelperText>
                                        </FormControl>
                                      }
                                    </div>
                                    <div style={styles.formRow}>
                                      <Button
                                        variant='contained'
                                        color='primary'
                                        disabled={!this.state.withdrawRequestDate || !this.state.refundReason || (this.state.refundReason === CHANGE_IN_REGISTRATION && !this.state.changeInRegistrationCourse) || (this.state.refundReason === OTHER && !this.state.refundReasonOtherText)}
                                        onClick={() => {
                                          if (!this.props.refundEligibility[c.id]) {
                                            if (!this.state.refundReason) {
                                              this.setState({[this._getErrorKey('refundReason')]: REQUIRED});
                                              return;
                                            }
                                            this.props.fetchRefundEligibility(c.id, {
                                              refund_request_date: moment.utc(this.state.withdrawRequestDate).format('YYYY-M-DD HH:mm:ss'),
                                              course_change: this.state.refundReason === CHANGE_IN_REGISTRATION ? this.state.changeInRegistrationCourse : null,
                                            });
                                            return;
                                          }
                                          this.setState({changeInRegistrationCourse: null});
                                          this.props.clearEnrollmentRefundEligibility(c.id);
                                        }}
                                      >
                                        {!this.props.refundEligibility[c.id] ? 'Calculate Withdraw Details' : 'Clear'}
                                      </Button>
                                      <div style={styles.spacer}></div>
                                      {this.props.loadingStates.fetchRefundEligibility && <CircularProgress />}
                                      {/* @TODO: conditionally render the rest of this form */}
                                      {/* @TODO: if other, required text field for other reason */}
                                    </div>
                                    {!!this.props.admin.perms[DEVELOPER] && <div style={styles.formRow}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={this.state.overideRefundEligibility}
                                            onChange={() => {
                                              this.setState((prevState) => {
                                                return {
                                                  overideRefundEligibility: !prevState.overideRefundEligibility,
                                                };
                                              });
                                            }}
                                            name='overideRefundEligibility'
                                            color='primary'
                                          />
                                        }
                                        label='This checkbox is for approving a refund outside of policy. This should only be used when absolutely necessary'
                                      />
                                    </div>}
                                    <div style={styles.formRow}>
                                      <FormControl style={{flexGrow: 1}} error={!!this.state[this._getErrorKey('refundReasonOtherText')]}>
                                        <TextField
                                          style={{...styles.textField, width: '100%'}}
                                          error={!!this.state[this._getErrorKey('withdrawRefundNotes')]}
                                          placeholder='Notes (Optional)'
                                          value={this.state.withdrawRefundNotes}
                                          onChange={(e) => this.setState({withdrawRefundNotes: e.target.value})}
                                          required={this.state.overideRefundEligibility}
                                          margin='dense'
                                        />
                                        <FormHelperText>{this.state[this._getErrorKey('withdrawRefundNotes')]}</FormHelperText>
                                      </FormControl>
                                    </div>
                                    {(this.props.refundEligibility[c.id] && this.state.refundReason !== CHANGE_IN_REGISTRATION)
                                      ? <p>
                                        {student.first_name} will receive the following due to the withdraw
                                        <br />
                                        <br />Grade: {this.props.refundEligibility[c.id].withdrawal_grade}
                                        <br />{this._refundMessage(c, this.props.refundEligibility[c.id]?.is_eligible, false, false)}
                                      </p>
                                      : <p>{this.props.refundEligibility[c.id] && !this.props.refundEligibility[c.id].has_change_registration_error_message &&
                                        `Grade: ${this.props.refundEligibility[c.id].withdrawal_grade}`}
                                        <br />
                                        <br />{this._refundMessage(c, this.props.refundEligibility[c.id]?.is_eligible, this.state.refundReason === CHANGE_IN_REGISTRATION, this.props.refundEligibility[c.id]?.has_change_registration_error_message)}
                                      </p>
                                    }
                                  </DialogContent>
                                  <DialogActions>
                                    <Button
                                      variant='contained'
                                      color='primary'
                                      disabled={this._disableConfirmWithdraw(c.id) || this.props.loadingStates.updateCanvasStudentCourseEnrollment || this.props.loadingStates.requestRefund}
                                      onClick={() => {
                                        if (!this.state.refundReason) {
                                          this.setState({[this._getErrorKey('refundReason')]: REQUIRED});
                                          return;
                                        }
                                        if (this.state.refundReason === OTHER && !this.state.refundReasonOtherText) {
                                          this.setState({[this._getErrorKey('refundReasonOtherText')]: REQUIRED});
                                          return;
                                        }
                                        if (this.state.refundReason === CHANGE_IN_REGISTRATION && !this.state.changeInRegistrationCourse) {
                                          this.setState({[this._getErrorKey('courseChangeInRegistration')]: REQUIRED});
                                          return;
                                        }
                                        if ((this.props.refundEligibility[c.id].is_eligible || this.state.overideRefundEligibility) && this.state.refundReason !== CHANGE_IN_REGISTRATION) {
                                          this._createRefund(c.id, null, '', () => {
                                            this._confirmWithdrawDialogClose();
                                            this.props.fetchStudentCourses(student.id);
                                          });
                                        } else if ((this.state.refundReason === CHANGE_IN_REGISTRATION && !this.props.refundEligibility[c.id].has_change_registration_error_message) || this.state.overideRefundEligibility) {
                                          this._createRefund(c.id, c.course_id, this.state.changeInRegistrationDiscountCode || '', () => {
                                            this._confirmWithdrawDialogClose();
                                            this.props.fetchStudentCourses(student.id);
                                          });
                                        } else { // this is just a regular withdraw without a refund
                                          this.props.updateCanvasStudentCourseEnrollment({
                                            studentId: student.id,
                                            withdraw: 1,
                                            enrollmentId: c.id,
                                            withdrawalGrade: this.props.refundEligibility[c.id].withdrawal_grade,
                                          }, () => {
                                            this._confirmWithdrawDialogClose();
                                          });
                                        }
                                      }}
                                    >
                                      Confirm withdraw
                                    </Button>
                                    {(this.props.loadingStates.updateCanvasStudentCourseEnrollment || this.props.loadingStates.requestRefund) && <CircularProgress size={30} />}
                                    <Button
                                      color='secondary'
                                      onClick={() => this._confirmWithdrawDialogClose()}
                                    >
                                      Close
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              }
                            </div>
                          }
                        </Grid>
                      }
                      <span style={styles.expand}></span>
                      <Tooltip title='Course syllabus at time of registration' aria-label='Course syllabi at time of registration'>
                        <IconButton
                          aria-controls='course-syllabus'
                          aria-haspopup='false'
                          color='secondary'
                          onClick={(event) => this.props.fetchCourseSyllabus(moment(c.created_at).unix(), c.code.replace(/\s/g, '').toLowerCase())}
                        >
                          <BookIcon></BookIcon>
                        </IconButton>
                      </Tooltip>
                      {!!menuItems.length &&
                        <span>
                          <IconButton
                            aria-controls='dev-options-menu'
                            aria-haspopup='true'
                            color='secondary'
                            onClick={(event) => this.setState({devMenuAnchorEl: event.currentTarget, enrollmentId: c.id})}
                          >
                            <DotsVerticalIcon size={18} />
                          </IconButton>
                          <Menu
                            id='dev-options-menu'
                            anchorEl={this.state.devMenuAnchorEl}
                            keepMounted
                            open={!!this.state.devMenuAnchorEl && c.id === this.state.enrollmentId}
                            onClose={() => this.setState({devMenuAnchorEl: null})}
                          >
                            {menuItems}
                          </Menu>
                        </span>
                      }
                      {this.state.isConfirmForceConcludeDialogOpen && c.id === this.state.enrollmentId && this._renderForceConcludeDialog()}
                      {this.state.assignInstructorDialogIsOpen && c.id === this.state.enrollmentId && this._renderAssignInstructorDialog(c.course_id)}
                    </Grid>
                  </div>
                  {(!!this.props.admin.perms[GRADES] || !!this.props.admin.perms[FINANCE]) && c.instructor_name !== 'unassigned' &&
                    <Grades
                      studentId={+this.props.studentId}
                      enrollmentId={c.id}
                      hideActualGrades={!this.props.admin.perms[GRADES]}
                    />
                  }
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      );
    }

    return <div><CircularProgress color='secondary' /></div>;
  }

  _renderForceConcludeDialog() {
    return (
      <Dialog
        open={!!this.state.isConfirmForceConcludeDialogOpen}
        onClose={() => {
          this.setState({isConfirmForceConcludeDialogOpen: false, enrollmentId: null}); this.props.clearNABCCanvasCoursePoints();
        }}
        // onEnter={() => this.props.fetchNABCCanvasCoursePoints(this.props.studentId, courseId)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Force conclude student?</DialogTitle>
        <DialogContent style={styles.innerDialogContent}>
          <b>{'PLEASE NOTE: this action will conclude the student enrollment in our database AS WELL AS in Canvas. Proceed with caution.'}</b><br />
          {"Are you sure you want to force conclude this student?  This should be used only if there's a rounding error or other mismatch with Canvas.  Please keep in mind that `grades` and `enrollments`.`final_letter_grade` may be out of sync if this feature is used.  `enrollments`.`final_letter_grade` takes precedence here, and this is what it means to \"force conclude\".  The following data will be saved in our database:"}
          <Grid container style={styles.dataWrapper}>
            <Grid item xs={5}>
              <Box display='flex'>NABC:</Box>
              <Paper
                style={{...styles.clickableStyle, ...(this.state.forceConcludeNabcHighlighted ? styles.highlighted : {})}}
                onClick={() => this.setState({forceConcludeNabcHighlighted: true})}
              >
                {this.props.loadingStates.fetchNABCCanvasCoursePoints && <CircularProgress size={30} />}
                {this.props.studentForceConcludePoints.nabc.score &&
                  <div>
                    <Box display='flex'>{`${this.props.studentForceConcludePoints.nabc.score}/${this.props.studentForceConcludePoints.nabc.possible_points}`}</Box>
                    <Box display='flex'>{this.props.studentForceConcludePoints.nabc.grade_percentage}</Box>
                    <Box display='flex'>{this.props.studentForceConcludePoints.nabc.letter_grade}</Box>
                  </div>
                }
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Box display='flex'>Canvas:</Box>
              <Paper
                style={{...styles.clickableStyle, ...(this.state.forceConcludeNabcHighlighted ? {} : styles.highlighted)}}
                onClick={() => this.setState({forceConcludeNabcHighlighted: false})}
              >
                {this.props.loadingStates.fetchNABCCanvasCoursePoints && <CircularProgress size={30} />}
                {this.props.studentForceConcludePoints.canvas.score &&
                  <div>
                    <Box display='flex'>{`${this.props.studentForceConcludePoints.canvas.score}/${this.props.studentForceConcludePoints.canvas.possible_points}`}</Box>
                    <Box display='flex'>{this.props.studentForceConcludePoints.canvas.grade_percentage}</Box>
                    <Box display='flex'>{this.props.studentForceConcludePoints.canvas.letter_grade}</Box>
                  </div>
                }
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            disabled={this.props.loadingStates.fetchNABCCanvasCoursePoints || this.props.studentForceConcludePoints.canvas.letter_grade === 'Course doesn\'t have a grading schema enabled'}
            onClick={() => {
              this.props.forceConcludeStudentCourse(this.state.enrollmentId, this.props.studentForceConcludePoints[this.state.forceConcludeNabcHighlighted ? 'nabc' : 'canvas'].letter_grade);
            }}
          >
            Confirm force conclude
          </Button>
          <Button
            color='secondary'
            onClick={() => {
              this.setState({isConfirmForceConcludeDialogOpen: false, enrollmentId: null});
              this.props.clearNABCCanvasCoursePoints();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  // @TODO: all _render*Dialog could be separate components to clean up this file
  _renderAssignInstructorDialog(nursingCourseId) {
    const _onInstructorAssignmentDialogClose = () => {
      this.setState({
        assignInstructorDialogIsOpen: false,
        idOfInstructorToAssign: null,
        enrollmentId: null,
      });
    };
    return (
      <Dialog
        open={true}
        onClose={() => _onInstructorAssignmentDialogClose()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Instructor assignment</DialogTitle>
        <DialogContent style={styles.innerDiaglogContent}>
          {this.props.assignableCourseInstructors[nursingCourseId]
            ? (
              <div>
                <p>Pick instructor to assign</p>
                <FormControl style={styles.formControl}>
                  <InputLabel id='instructor-name-label'>Instructor Name</InputLabel>
                  <Select
                    style={styles.selectField}
                    labelId='instructor-name-label'
                    value={this.state.idOfInstructorToAssign}
                    onChange={(e) => this.setState({idOfInstructorToAssign: e.target.value})}
                    input={<Input id='idOfInstructorToAssign' name='idOfInstructorToAssign' />}
                  >
                    {this.props.assignableCourseInstructors[nursingCourseId].map((i) => {
                      return <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            ) : (
              <span>
                <CircularProgress size={30} />
                Loading instructors...
              </span>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            disabled={!this.state.idOfInstructorToAssign}
            onClick={() => {
              this.props.manuallyAssignInstructor(
                this.state.enrollmentId,
                this.state.idOfInstructorToAssign,
                () => {
                  this.props.fetchUnassignedStudentIds();
                  _onInstructorAssignmentDialogClose();
                }
              );
            }}
          >
            Confirm
          </Button>
          {this.props.loadingStates.manuallyAssignInstructor && <CircularProgress size={30} />}
          <Button
            color='secondary'
            onClick={() => _onInstructorAssignmentDialogClose()}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _renderField(label, content, action) {
    return (
      <div style={styles.formFieldWrapper}>
        <h6 style={styles.formFieldLabel}>{label}</h6>
        <div style={styles.formFieldContent}>{content} {action}</div>
      </div>
    );
  }

  _formatName(firstName, middleName, lastName) {
    return `${firstName} ${middleName}${middleName ? ' ' : ''}${lastName}`;
  }

  _formatAddress(streetAddress, addressLineTwo, city, state, zip, country) {
    return (
      <span>
        {streetAddress}<br />
        {addressLineTwo && (
          <>
            {addressLineTwo}<br />
          </>
        )}
        {(!!city && !!state) &&
          <>{`${city}, ${state} ${zip}`}<br /></>
        }
        {country}
      </span>
    );
  }

  _formatSchoolOfIntent(name, city, state, program) {
    return (
      <span>
        {name}<br />
        {city}
        {(city && state) && ', '}
        {state}
        {(city || state) && program && <br />}
        {program}
      </span>
    );
  }

  _getPreviousInfoByGroup(student) {
    const previousNames = [];
    const previousAddresses = [];
    const previousEmails = [];
    const previousPhoneNumbers = [];
    const previousSchoolOfIntent = [];
    const previousNotes = [];

    const snapshotStudent = {...student}; // this is used to track all student fields at each change (because previous records contain only the diff between changes...this will have all attributes)

    (this.props.studentsPreviousRecords[student.id] || []).forEach((previousRecord) => {
      Object.keys(previousRecord).forEach((attribute) => {
        if (previousRecord[attribute]) {
          snapshotStudent[attribute] = previousRecord[attribute];
        }
      });
      const currItem = {
        id: previousRecord.id,
        notes: previousRecord.notes,
        inserted_at: moment(previousRecord.inserted_at).format('L LT'),
        changed_by: previousRecord.changed_by,
      };
      if (previousRecord.first_name || previousRecord.middle_name || previousRecord.last_name) {
        previousNames.push({
          ...currItem,
          data: this._formatName(snapshotStudent.first_name, snapshotStudent.middle_name, snapshotStudent.last_name),
        });
      }
      if (previousRecord.address_id) {
        previousAddresses.push({
          ...currItem,
          data: this._formatAddress(
            previousRecord.address.line_1,
            previousRecord.address.line_2,
            previousRecord.address.city,
            previousRecord.address.state,
            previousRecord.address.zip_code,
            previousRecord.address.country,
          ),
        });
      }
      if (previousRecord.email) {
        previousEmails.push({
          ...currItem,
          data: previousRecord.email,
        });
      }
      if (previousRecord.phone) {
        previousPhoneNumbers.push({
          ...currItem,
          data: getFormattedPhone(previousRecord.phone),
        });
      }
      if (previousRecord.school_of_intent_id) {
        previousSchoolOfIntent.push({
          ...currItem,
          data: this._formatSchoolOfIntent(
            previousRecord.school_of_intent.name,
            previousRecord.school_of_intent.city,
            previousRecord.school_of_intent.state,
            previousRecord.school_of_intent.program,
          ),
        });
      }
      if (previousRecord.notes) {
        previousNotes.push({
          ...currItem,
          data: previousRecord.notes,
        });
      }
    });

    return {
      previousNames,
      previousAddresses,
      previousEmails,
      previousPhoneNumbers,
      previousSchoolOfIntent,
      previousNotes,
    };
  }

  _generateMenuKey(menuType) {
    return `${menuType}MenuOpen`;
  }

  _renderPreviousInfo(previousRecords, infoType) {
    return (
      <div>
        <IconButton
          style={styles.previousRecordsIconWrapper}
          color='secondary'
          onClick={(event) => this.setState({anchorEl: event.currentTarget, [this._generateMenuKey(infoType)]: true})}
        >
          <RestoreClockIcon size={18} />
        </IconButton>
        <Menu
          anchorEl={this.state.anchorEl}
          open={!!this.state[this._generateMenuKey(infoType)]}
          onClose={() => this.setState({
            anchorEl: null,
            [this._generateMenuKey(infoType)]: false,
          })}
        >
          {previousRecords.map((previousRecord) => (
            <Tooltip
              key={previousRecord.id}
              title={
                <>
                  {/* @TODO: mysql inserted_at is CURRENT_TIMESTAMP, but it should be stored in utc, not est */}
                  <p>Deprecated <strong>{previousRecord.inserted_at}</strong> by <strong>{previousRecord.changed_by}</strong>.</p>
                  <p>Notes: <strong>{previousRecord.notes}</strong></p>
                </>
              }
            >
              <MenuItem open={this.state[this._generateMenuKey(infoType)]}>
                <span style={styles.tooltipTrigger}>
                  {previousRecord.data}
                </span>
              </MenuItem>
            </Tooltip>
          ))}
        </Menu>
      </div>
    );
  }

  _closeDiscountCodeCreateDialog() {
    this.setState({
      displayDiscountCodeDialog: false,
      courseIdForDiscountCode: '',
      enrollmentId: '',
    });
  }

  render() {
    if (!this.props.student) {
      return <CircularProgress color='secondary' />;
    }

    if (this.props.renderCoursesOnly) {
      return (
        <>
          {this._renderStudentCourses(this.props.student)}
          {this.state.displayTranscriptHistoryRequests && this._renderTranscriptHistoryDialog(this.props.student)}
        </>
      );
    }

    return (
      <Paper style={styles.titleWrapper}>
        <div style={styles.detailsOuterWrapper}>
          {this.state.displayConsortiumGradeChangeDialog && this._renderConsortiumGradeChangeDialog()}
          {this._renderStudentInfo(this.props.student)}
          {this._renderStudentCourses(this.props.student)}
          {this.state.displayDialogForMultipleRefundCancelsOnWithdraw && this._renderUnwithdrawSnackBar()}
          {this.state.displayDiscountCodeDialog &&
            <CreateDiscountDialog
              getDiscountCodeValue={(discountCodeValue) => this.props.applyDiscountCodeToEnrollment(this.state.enrollmentId, discountCodeValue)}
              open={this.state.displayDiscountCodeDialog}
              closeDialog={() => this._closeDiscountCodeCreateDialog()}
              courseForDiscount={Object.values(this.props.courses).find((course) => course.id === this.state.courseIdForDiscountCode)}
            />
          }
          {(!!this.props.admin.perms[EDIT_STUDENT_INFO] || !!this.props.admin.perms[DISABLE_STUDENT]) &&
            <div style={styles.actionsWrapper}>
              <div style={styles.actionOptions}>
                <IconButton
                  color='secondary'
                  onClick={() => this.setState({studentSettingsDialogOpen: true})}
                >
                  <SettingsIcon />
                </IconButton>
                {this.state.studentSettingsDialogOpen && this._renderStudentSettingsDialog(this.props.student)}
                {!!this.props.admin.perms[EDIT_STUDENT_INFO] &&
                  <IconButton
                    color='secondary'
                    onClick={() => this.setState({studentIdToEdit: this.props.student.id})}
                  >
                    <PencilIcon />
                  </IconButton>
                }
                {this.state.studentIdToEdit && <EditStudentDialog studentId={this.props.student.id} onClose={() => this.setState({studentIdToEdit: null})} />}
                {this.state.displayTranscriptHistoryRequests && this._renderTranscriptHistoryDialog(this.props.student)}
              </div>
            </div>
          }
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    admin: state.admin,
    apiCanvasUpdateStudentEnrollment: state.apiCanvasUpdateStudentEnrollment,
    assignableCourseInstructors: state.assignableCourseInstructors,
    courses: state.courses,
    coursesOffered: Object.values(state.courses).sort((a, b) => (a.code).localeCompare(b.code)),
    departmentChairCourses: state.departmentChairCourses,
    grades: state.grades,
    loadingStates: state.loadingStates,
    refundEligibility: state.refundEligibility,
    student: state.students[props.studentId],
    studentCognitoInfo: state.studentCognitoInfo,
    studentForceConcludePoints: state.studentForceConcludePoints,
    studentsPreviousRecords: state.studentsPreviousRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    applyDiscountCodeToEnrollment,
    backfillGrades,
    clearCanvasStudentEnrollmentUpdateFlag,
    clearEnrollmentRefundEligibility,
    clearNABCCanvasCoursePoints,
    closeForceConcludeDialog,
    extendStudentQuizTime,
    fetchAssignableCourseInstructors,
    fetchCognitoStudentData,
    fetchCourseSyllabus,
    fetchCourses,
    fetchGrades,
    fetchNABCCanvasCoursePoints,
    fetchRefundEligibility,
    fetchStudentCourses,
    fetchUnassignedStudentIds,
    fetchPreviousInfoForStudent,
    forceConcludeStudentCourse,
    manuallyAssignInstructor,
    manuallyEnrollStudent,
    requestRefund,
    updateCanvasStudentCourseEnrollment,
    updateEnrollments,
    updateTransactionEnrollment,
    updateStudent,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
